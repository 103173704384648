<template>
  <div class="pa-2 ">
    <page-card
        cardTitle="Absent Friends"
        cardTitleIcon="mdi-book-open-page-variant"
    >

      <v-card-text>
        <p class="text-h6 font-weight-bold">
          This is where you will create your Absent Friends Booklets.
        </p>

          <p>
            <strong>
              If you are not planning on choosing any Absent Friends Booklets as part of your package, that’s absolutely fine. In this case, you will still need to ‘fill’ in the fields below to be able to submit your Love Story booklet for checking. All you need to do is add a single letter in each field and then you can save it and move on to submitting your Booklet.
            </strong>
          </p>

          <p>
          For the most part, the booklets have the same content as the standard wedding guests’ booklets, with the exception of 
          one or two activities that are only suitable for guests who are physically present, and an alternative introduction and farewell message.
          </p>

          <p>
          You can make those changes below, in the respective boxes. If you did not choose an activity that is unsuitable for 
          Absent Friends, then no activity box will appear below. Changing the activities and text here will not change your standard booklet.
          </p>

          <p class="text-h7 font-weight-bold">
          Here are some tips for wording your alternative text:
          </p>

          <p>
          The welcome should be two or three sentences at most. If you are stuck for inspiration, 
          here is a suggestion for you. Feel free to copy and paste the contents into the text box.
          </p>

          <p class="font-italic">
          We wish it had been possible for you to join us in person on our special day. However, we hope you will join in
           ‘virtually’ by completing the fun activities in this booklet and by following and sharing the celebrations on our Wedding Wall. With love…..
          </p>

      </v-card-text>


      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
          class="pa-4"
      >

        <v-textarea
            v-model="absIntro"
            :rules="introRules"
            label="Intro Text (absent friends)"
            :counter="270"
            outlined
            @change="fieldValueChanged"
        />

          <v-card-text>
            <p>
            The farewell should also be two or three sentences at most. 
            </p>

            <p>
            Please see below a suggestion in case if you are stuck. Feel free to copy and paste the contents into the text box.
            </p>

            <p class="font-italic">
            Although you were unable to be with us in person, we hope you have been able to join in with us today by completing 
            the activities. Don’t forget to upload your selfies and creations to the Wedding Wall for us to treasure forever.
            </p>
          </v-card-text>


        <v-textarea
            v-model="absFarewell"
            :rules="farewellRules"
            label="Farewell Text (absent friends)"
            :counter="400"
            outlined
            @change="fieldValueChanged"
        />

        <div class="mt-8">
          <v-btn
              :disabled="!valid || readOnlyMode"
              type="submit"
              color="green"
              class="mr-4"
          >
            Save
          </v-btn>
          <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
          <v-btn :disabled="!valid" @click="preview( {absentFriends: true} )">Preview</v-btn>
        </div>

      </v-form>

      <div v-if="altChallengeRequired('h')" class="mt-8">
        <v-card-text>
        <p>The ‘Find the Guest’ activity requires guests to physically be at the wedding. As such, it is not suitable for the Absent Friends booklet. 
          Please choose an alternative activity.
        </p>
        </v-card-text>

        <challenge-selector-absent-friends
            challengeMapping="absentFriendsH"
            key="alternativeToChallengeH"
        />
        <keep-alive>
          <component
              v-bind:is="challengeComponentToReplaceH"
              :key="challengeKeyToReplaceH"
              :absentFriends=true
              class="pa-4"
          />
        </keep-alive>
      </div>

      <div v-if="altChallengeRequired('i')" class="mt-8">
        <v-card-text>
          <p>The ‘Wedding Speech Bingo’ activity requires guests to physically be at the wedding. As such, it is 
            not suitable for the Absent Friends booklet. Please choose an alternative activity.
          </p>
        </v-card-text>
        
        <challenge-selector-absent-friends
            challengeMapping="absentFriendsI"
            key="alternativeToChallengeI"
        />
        <keep-alive>
          <component
              v-bind:is="challengeComponentToReplaceI"
              :key="challengeKeyToReplaceI"
              :absentFriends=true
              class="pa-4"
          />
        </keep-alive>
      </div>

    </page-card>
  </div>
</template>


<script>
import ChallengeA from "@/components/Challenges/ChallengeA";
import ChallengeB from "@/components/Challenges/ChallengeB";
import ChallengeC from "@/components/Challenges/ChallengeC";
import ChallengeD from "@/components/Challenges/ChallengeD";
import ChallengeE from "@/components/Challenges/ChallengeE";
import ChallengeJ from "@/components/Challenges/ChallengeJ";
import ChallengeL from "@/components/Challenges/ChallengeL";
import ChallengeM from "@/components/Challenges/ChallengeM";
import PageCard from "@/components/PageCard";
import {form} from "@/mixins/form";
import ChallengeSelectorAbsentFriends from "@/components/ChallengeSelectorAbsentFriends";

export default {
  name: "AbsentFriends",
  components: {
    ChallengeA,
    ChallengeB,
    ChallengeC,
    ChallengeD,
    ChallengeE,
    ChallengeJ,
    ChallengeL,
    ChallengeM,
    ChallengeSelectorAbsentFriends,
    PageCard
  },
  mixins: [form],
  data() {
    return {
      valid: true,
    }
  },
  methods: {
    altChallengeRequired(challengeId) {
      // Check if a certain challenge ID has been assigned to a page.
      const pageChallengeMap = this.$store.state.pageChallengeMap
      for (const key in pageChallengeMap) {
        if (pageChallengeMap[key] === challengeId) {
          return true
        }
      }
    },
  },
  computed: {
    absIntro: {
      get: function () {
        // TODO: This is horribly fragile.
        // Create a getter that finds the object by searching for the "p2" key instead.
        return this.$store.state.order.pages[1].data.abs_intro
      },
      set(value) {
        this.$store.commit("setP2AbsIntro", value)
      }
    },
    absFarewell: {
      get: function () {
        // TODO: This is horribly fragile.
        // Create a getter that finds the object by searching for the "p2" key instead.
        return this.$store.state.order.pages[2].data.abs_intro
      },
      set(value) {
        this.$store.commit("setP11AbsFarewell", value)
      }
    },
    challengeKeyToReplaceH() {
      return this.$store.state.pageChallengeMap["absentFriendsH"]
    },
    challengeKeyToReplaceI() {
      return this.$store.state.pageChallengeMap["absentFriendsI"]
    },
    challengeComponentToReplaceH() {
      const mappedChallenge = this.$store.state.pageChallengeMap["absentFriendsH"]
      if (mappedChallenge !== "") {
        return "Challenge" + mappedChallenge.toUpperCase()
      }
      return ""
    },
    challengeComponentToReplaceI() {
      const mappedChallenge = this.$store.state.pageChallengeMap["absentFriendsI"]
      if (mappedChallenge !== "") {
        return "Challenge" + mappedChallenge.toUpperCase()
      }
      return ""
    },
  }
}
</script>
